<template>
  <div>
    <v-row class="ma-0 pa-0" dense no-gutters align="center" justify="center">
      <v-col class="pr-4 pt-2" cols="12" md="4" lg="3">
        <project-filter
          :value="telework"
          category="TELEWORK"
          @input="v => $emit('update:telework', v)"
        />
      </v-col>
      <v-col class="pr-4 pt-2" cols="12" md="4" lg="3">
        <project-filter
          :value="elearning"
          category="ELEARNING"
          @input="v => $emit('update:elearning', v)"
        />
      </v-col>
      <v-col class="pr-4 pt-2" cols="12" md="4" lg="2">
        <project-filter
          :value="telecare"
          category="TELECARE"
          @input="v => $emit('update:telecare', v)"
        />
      </v-col>
      <v-col class="pr-4 pt-2" cols="12" md="6" lg="2">
        <project-filter
          :value="other"
          category="OTHER"
          @input="v => $emit('update:other', v)"
        />
      </v-col>
      <v-col class="pr-4 pt-2" cols="12" md="6" lg="2">
        <project-filter
          :value="unknown"
          category="UNKNOWN"
          @input="v => $emit('update:unknown', v)"
        />
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2">
      <v-col class="pr-4 pt-3" cols="12" md="6" lg="4">
        <dropdown-filter
          class="mr-2"
          :value="source"
          source
          @input="v => $emit('update:source', v)"
        />
      </v-col>
      <v-col class="pr-4 pt-3" cols="12" md="6" lg="4">
        <dropdown-filter :value="status" status @input="v => $emit('update:status', v)" />
      </v-col>
      <v-col class="pt-2 pr-3" cols="12" md="6" lg="4">
        <dropdown-filter
          :value="country"
          country
          @input="v => $emit('update:country', v)"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="pr-4 pt-2" cols="12" md="9">
        <v-btn block color="primary" outlined @click="search">
          <v-icon left>mdi-magnify</v-icon>
          Search projects
        </v-btn>
      </v-col>
      <v-col cols="12" md="3" class="pr-4 pt-2">
        <v-btn block color="secondary" @click="reset">
          <v-icon left>mdi-filter-off</v-icon>
          Reset filters
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DropdownFilter from '@/components/Filters/DropdownFilter.vue'
import ProjectFilter from '@/components/Filters/ProjectFilter.vue'

export default {
  name: 'IntranetFilterBar',
  components: { DropdownFilter, ProjectFilter },
  props: {
    telework: {
      type: Boolean,
      required: true
    },
    elearning: {
      type: Boolean,
      required: true
    },
    telecare: {
      type: Boolean,
      required: true
    },
    other: {
      type: Boolean,
      required: true
    },
    unknown: {
      type: Boolean,
      required: true
    },
    source: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true
    },
    status: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true
    },
    country: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    search() {
      this.$emit('search')
    },
    reset() {
      this.$emit('reset')
    }
  }
}
</script>
