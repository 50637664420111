<i18n>
{
  "en": {
    "error": {
      "unauthorized": "The credentials provided are incorrect. Check your credentials and try again."
    }
  }
}
</i18n>
<template>
  <v-container fluid class="login">
    <v-row justify="center" align="center">
      <v-col cols="6">
        <v-img :src="logo" width="400" class="mx-auto my-8" />
        <bml-alert type="error" v-if="error">{{ error }}</bml-alert>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="6">
        <bml-login-form
          focus-username
          :username="username"
          :password="password"
          :loading="isLoginFormLoading"
          @auth:login="handleLogin"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Logo from '@/assets/img/atech.png'
import { TEST_API_PASSWORD, TEST_API_USER } from '@/core/config'
import { isDevelopment } from '@/core/helpers/env'

export default {
  name: 'Login',
  data() {
    return {
      nextPage: { name: 'intranet' },
      error: '',
      username: '',
      password: '',
      isLoginFormLoading: false
    }
  },
  computed: {
    logo() {
      return Logo
    },
    loginRedirect() {
      const { next = null } = this.$route?.query || {}
      return next || this.nextPage
    }
  },
  created() {
    if (this.isUserLoggedIn) {
      return this.$router.replace(this.nextPage)
    }
    if (isDevelopment()) {
      this.username = TEST_API_USER
      this.password = TEST_API_PASSWORD
    }
  },
  methods: {
    async handleLogin(formData) {
      const data = Object.fromEntries(formData.entries())
      this.isLoginFormLoading = true
      this.error = ''
      try {
        await this.$store.dispatch('auth/login', data)
        this.$router.push(this.loginRedirect)
      } catch (err) {
        const { status = null } = err?.response || {}
        switch (status) {
          case 401: // Unautorized
            this.error = this.$t('error.unauthorized')
            break
          default:
            this.error = this.$t('error.general')
        }
      } finally {
        this.isLoginFormLoading = false
      }
    }
  }
}
</script>
