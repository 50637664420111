<template>
  <project-card :project="project" intranet>
    <template #actions>
      <v-container class="ml-1 mr-1 pa-0">
        <v-row justify="center" align="center" no-gutters class="mt-2 mb-2">
          <v-col justify="center" align="center" cols="6" class="pr-2">
            <v-icon class="mr-1">mdi-list-status</v-icon>
            Review:
            <span style="font-weight: 700">{{ publish }}</span>
          </v-col>
          <v-col justify="center" align="center" cols="6" class="pl-2">
            <v-icon class="mr-1">mdi-semantic-web</v-icon>
            Source:
            <span style="font-weight: 700">{{ source }}</span>
          </v-col>
        </v-row>
        <v-divider />
        <v-row justify="center" align="center" no-gutters class="mt-2 mb-2">
          <v-col justify="center" align="center" cols="6" class="pl-2">
            <v-icon class="mr-1">mdi-clock</v-icon>
            Created:
            <span style="font-weight: 700">
              {{ createdAtFormat }}
            </span>
          </v-col>
          <v-col justify="center" align="center" cols="6" class="pl-2">
            <v-icon class="mr-1">mdi-clock</v-icon>
            Update:
            <span style="font-weight: 700">
              {{ updatedAtFormat }}
            </span>
          </v-col>
        </v-row>
        <v-divider />
        <v-row justify="center" align="center" no-gutters class="mt-2 mb-2">
          <v-col justify="center" align="center" cols="6" class="pl-2">
            <v-icon class="mr-1">mdi-database</v-icon>
            DB Identifier:
            <span style="font-weight: 700">
              {{ project.id }}
            </span>
          </v-col>
          <v-col justify="center" align="center" cols="6" class="pr-2">
            <v-row justify="center" align="center" no-gutters>
              <div class="d-flex align-center">
                <v-img left :width="40" :src="bigmlLogo" />
                <span class="ml-2">
                  BigML Score:
                  <span style="font-weight: 700">
                    {{ Math.trunc(project.review.order * 1000) / 1000 }}
                  </span>
                </span>
              </div>
            </v-row>
          </v-col>
        </v-row>
        <v-divider />
        <v-row
          justify="center"
          align="center"
          no-gutters
          class="mt-3"
          v-if="project.review.review_status == 'REJECTED'"
        >
          <v-col
            justify="center"
            align="center"
            cols="12"
            class="mb-3 pr-2"
            v-if="rejectionUser"
          >
            <v-icon>mdi-account</v-icon>
            Rejected by:
            <span style="font-weight: 700">
              {{ rejectionUser || '' }}
            </span>
          </v-col>
          <v-col justify="center" align="center" cols="12" class="mb-3 pr-2">
            <v-icon>mdi-chat</v-icon>
            Rejection comment:
            <span style="font-weight: 700">
              {{ rejectionComment || 'No comment' }}
            </span>
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
        </v-row>
        <v-row justify="center" align="center" no-gutters class="mt-2 mr-4 ml-4">
          <v-col class="pr-2 mt-2" cols="6">
            <project-filter v-model="telework" small category="TELEWORK" />
          </v-col>
          <v-col class="pr-2 mt-2" cols="6">
            <project-filter v-model="elearning" small category="ELEARNING" />
          </v-col>
          <v-col class="pr-2 mt-2" cols="6">
            <project-filter v-model="telecare" small category="TELECARE" />
          </v-col>
          <v-col class="pr-2 mt-2" cols="6">
            <project-filter v-model="other" small category="OTHER" />
          </v-col>
          <v-col cols="12" class="pt-5 text-center" v-if="labels.length == 0">
            <v-icon color="error" class="mr-2">mdi-alert</v-icon>
            At least one category should be selected: Telework, e-Learning, Telecare,
            Other
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-4 mb-4" no-gutters>
          <v-col class="pa-1" cols="6">
            <v-btn
              color="error"
              block
              :aria-label="'Reject project ' + project.name"
              :disabled="labels.length == 0"
              :loading="loadingCreateOrUpdate == project.id && action == 'reject'"
              @click="reject"
            >
              <v-icon left>mdi-delete</v-icon>
              Reject
            </v-btn>
          </v-col>
          <v-col class="pa-1" cols="6">
            <v-btn
              color="success"
              block
              :aria-label="'Publish project ' + project.name"
              :disabled="labels.length == 0"
              :loading="loadingCreateOrUpdate == project.id && action == 'publish'"
              @click="publishProject"
            >
              <v-icon left>mdi-web</v-icon>
              {{ publish == 'PUBLISHED' ? 'Apply changes' : 'Publish' }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </project-card>
</template>

<script>
import logo from '@bigml/bmlapp/src/assets/img/logo/bigml_version_light_ml.png'
import { formatDateTime } from '@bigml/bmlapp/src/core/helpers/datetime'
import { mapState } from 'vuex'

import ProjectFilter from '@/components/Filters/ProjectFilter.vue'
import ProjectCard from '@/views/Project/ProjectCard.vue'

export default {
  name: 'IntranetProjectCard',
  components: { ProjectCard, ProjectFilter },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      bigmlLogo: logo,
      telework: this.project.labels.includes('TELEWORK'),
      elearning: this.project.labels.includes('ELEARNING'),
      telecare: this.project.labels.includes('TELECARE'),
      other: this.project.labels.includes('OTHER'),
      action: false
    }
  },
  computed: {
    ...mapState('project', ['loadingCreateOrUpdate']),
    publish() {
      return this.project.review ? this.project.review.review_status : 'PENDING'
    },
    labels() {
      let output = []
      if (this.telework) {
        output.push('TELEWORK')
      }
      if (this.elearning) {
        output.push('ELEARNING')
      }
      if (this.telecare) {
        output.push('TELECARE')
      }
      if (this.other) {
        output.push('OTHER')
      }
      return output
    },
    rejectionUser() {
      let clist = this.project.review.comment.split('||')
      return clist.length > 1 ? clist[0] : ''
    },
    rejectionComment() {
      let clist = this.project.review.comment.split('||')
      return clist.length > 1 ? clist[1] : this.project.review.comment
    },
    source() {
      let sid = this.project.source_id || ''
      let sid_source = sid.split('@')
      let output = this.project.source
      if (sid_source.length > 1 && sid_source[0] != 'loaded') {
        output = output + ' + ' + sid_source[0]
      }
      return output
    },
    createdAtFormat() {
      return formatDateTime(this.project.created_at, {
        formatStr: 'yyyy-MM-dd'
      })
    },
    updatedAtFormat() {
      return formatDateTime(this.project.created_at, {
        formatStr: 'yyyy-MM-dd   hh:mm'
      })
    }
  },
  created() {},
  methods: {
    reject: function () {
      this.action = 'reject'
      this.$emit('reject', this.project, this.labels)
    },
    publishProject: function () {
      this.action = 'publish'
      this.$emit('publish', this.project, this.labels)
    }
  }
}
</script>
