<template>
  <bml-vuex-resource-iterator
    auto-refresh
    vuex-namespace="project"
    hide-default-footer
    :cols-props="{ cols: 12, md: 6, xl: 4 }"
    :external-filters="filters"
    :auto-refresh-interval="30000"
  >
    <template #header="{ loading }">
      <v-progress-linear color="primary" class="mb-5" indeterminate v-if="loading" />
    </template>

    <template #loading>
      <div style="height: 100px; text-align: center">
        <p>Loading. Please wait...</p>
      </div>
    </template>

    <template #item="{ item }">
      <intranet-project-card :project="item" v-on="$listeners"></intranet-project-card>
    </template>

    <template #footer="{ loading, pagination, totalPages }">
      <div class="text-center mt-8 mb-8">
        <v-progress-linear
          color="primary"
          height="5"
          class="mb-5"
          indeterminate
          v-if="loading && totalPages > 0"
        />
        <v-pagination
          v-model="pagination.page"
          :length="totalPages"
          :total-visible="7"
          circle
          v-if="totalPages > 1"
        ></v-pagination>
      </div>
    </template>
  </bml-vuex-resource-iterator>
</template>

<script>
import IntranetProjectCard from '@/views/Intranet/IntranetProjectCard.vue'

export default {
  name: 'IntranetProjectList',
  components: { IntranetProjectCard },
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      value: false
    }
  },
  methods: {}
}
</script>
