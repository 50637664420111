export default {
  setTheme(state, theme) {
    localStorage.setItem('theme', theme)
    state.theme = theme
  },
  setThemeDarkBool(state, theme) {
    const themeStr = theme ? 'dark' : 'light'
    localStorage.setItem('theme', themeStr)
    state.theme = themeStr
  },
  setShowAppBar(state, value) {
    state.showAppBar = value
  },
  setShowSideBar(state, value) {
    state.showSideBar = value
  },
  setActiveFiltersTool(state, value) {
    state.activeFiltersTool = value
  },
  setActiveFiltersIntranet(state, value) {
    state.activeFiltersIntranet = value
  }
}
