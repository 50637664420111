/**
 * Navigation guard to set meta tags in page.
 * @param {Object} to
 * @param {Object} _from
 * @param {Function} _next
 * @returns {Function} Next route
 */

export default async function setMeta(to, _from, _next) {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta?.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta?.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[bmlapp-router-meta]')).map(el =>
    el.parentNode.removeChild(el)
  )

  // Turn the meta tag definitions into actual elements in the head.
  if (nearestWithMeta) {
    nearestWithMeta.meta.metaTags
      .map(tagDef => {
        const tag = document.createElement('meta')

        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key])
        })

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('bmlapp-router-meta', '')

        return tag
      })
      // Add the meta tags to the document head.
      .forEach(tag => document.head.appendChild(tag))
  }
}
