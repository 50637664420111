import {
  DEFAULT_GETTERS,
  DEFAULT_PAGINATION,
  DEFAULT_STATE,
  gen_crud_actions,
  gen_crud_mutations
} from '@bigml/bmlapp/src/core/store/resources'

import { PublicProject } from '@/core/api'

const PAGINATION = { ...DEFAULT_PAGINATION(5), sortBy: [] }
const INIT_STATE = { ...DEFAULT_STATE() }

export default {
  namespaced: true,
  state: {
    ...INIT_STATE,
    pagination: PAGINATION
  },
  getters: { ...DEFAULT_GETTERS },
  actions: {
    ...gen_crud_actions(PublicProject)
  },
  mutations: { ...gen_crud_mutations(INIT_STATE) }
}
