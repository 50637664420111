import { Auth } from '@/core/api'
import { AUTH_COOKIES, REFRESH_TOKENS_INTERVAL } from '@/core/config'
import {
  addToCookieStore,
  deleteFromCookieStore,
  retrieveFromCookieStore
} from '@/core/helpers/storage'

export default {
  namespaced: true,
  state: {
    accessToken: null,
    refreshToken: null,
    expiresAt: null,
    pollingRefreshToken: null
  },
  getters: {
    accessToken({ accessToken }) {
      return accessToken
    },
    refreshToken({ refreshToken }) {
      return refreshToken
    },
    expiresAt({ expiresAt }) {
      return expiresAt
    },
    pollingRefreshToken({ pollingRefreshToken }) {
      return pollingRefreshToken
    },
    isUserLoggedIn({ accessToken, expiresAt }) {
      return Boolean(accessToken) && expiresAt > Date.now()
    }
  },
  actions: {
    async init({ dispatch, state }) {
      if (!state.accessToken || !state.refreshToken || !state.expiresAt) {
        state.accessToken =
          (await retrieveFromCookieStore(AUTH_COOKIES.ACCESS_TOKEN)) || null
        state.refreshToken =
          (await retrieveFromCookieStore(AUTH_COOKIES.REFRESH_TOKEN)) || null
        state.expiresAt = (await retrieveFromCookieStore(AUTH_COOKIES.EXPIRES_AT)) || null
        dispatch('user/me', null, { root: true })
      }
    },
    async login({ commit, dispatch }, payload = {}) {
      const { password, username } = payload
      const { access, refresh } = await Auth.token(username, password)
      commit('setTokens', { access, refresh })
      dispatch('user/me', null, { root: true })
    },
    logout({ commit }) {
      commit('clearTokens')
    },
    async refreshTokens({ commit, getters }, force = false) {
      const expiresAt = Number(getters.expiresAt)
      const refreshToken = getters.refreshToken
      if (!refreshToken || (expiresAt > Date.now() && !force)) {
        return
      }
      const { access } = await Auth.refreshTokens(refreshToken)
      commit('setTokens', { access, refresh: refreshToken })
    },
    togglePollingRefreshToken({ commit, dispatch, getters }, payload) {
      let polling = null
      if (payload) {
        // calculate polling time to refresh JWT tokens automatically
        // timer will be set to (Time to expire - 10%)
        const expiration = getters.expiresAt - Date.now()
        const pollingTime = expiration - Math.round(expiration * 0.05)
        polling = setTimeout(() => dispatch('refreshTokens'), pollingTime)
      }
      commit('setPollingRefreshToken', polling)
    }
  },
  mutations: {
    setTokens(state, { access, refresh }) {
      const expiresInSecs = Math.floor(Number(REFRESH_TOKENS_INTERVAL))
      const expiresAt = Date.now() + expiresInSecs * 1000
      addToCookieStore(AUTH_COOKIES.ACCESS_TOKEN, access, expiresInSecs)
      addToCookieStore(AUTH_COOKIES.EXPIRES_AT, expiresAt, expiresInSecs)
      addToCookieStore(AUTH_COOKIES.REFRESH_TOKEN, refresh, expiresInSecs)
      state.accessToken = access
      state.expiresAt = expiresAt
      state.refreshToken = refresh
    },
    clearTokens(state) {
      deleteFromCookieStore(AUTH_COOKIES.ACCESS_TOKEN)
      deleteFromCookieStore(AUTH_COOKIES.REFRESH_TOKEN)
      deleteFromCookieStore(AUTH_COOKIES.EXPIRES_AT)
      clearTimeout(state.pollingRefreshToken)
      state.accessToken = null
      state.refreshToken = null
      state.expiresAt = null
      state.pollingRefreshToken = null
    },
    setPollingRefreshToken(state, timer) {
      if (!timer) {
        clearTimeout(state.pollingRefresh)
      }
      state.pollingRefresh = timer
    }
  }
}
