import {
  DEFAULT_GETTERS,
  DEFAULT_PAGINATION,
  DEFAULT_STATE,
  gen_crud_actions,
  gen_crud_mutations
} from '@bigml/bmlapp/src/core/store/resources'

import { Review } from '@/core/api'
import { DEFAULT_ITEMS_PER_PAGE } from '@/core/config'

const PAGINATION = { ...DEFAULT_PAGINATION(DEFAULT_ITEMS_PER_PAGE) }
const INIT_STATE = { ...DEFAULT_STATE() }

export default {
  namespaced: true,
  state: {
    ...INIT_STATE,
    pagination: PAGINATION
  },
  getters: { ...DEFAULT_GETTERS },
  actions: {
    ...gen_crud_actions(Review)
  },
  mutations: { ...gen_crud_mutations(INIT_STATE) }
}
