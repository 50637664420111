import { APP_TITLE } from '@/core/config'
import i18n from '@/plugins/i18n'

export default {
  path: '/tool',
  name: 'tool',
  meta: {
    requiresAuth: false,
    title: i18n.t('page-titles.tool', [APP_TITLE])
  },
  component: () => import('@/views/Tool/Tool.vue')
}
