<template>
  <v-container class="auth" fill-height>
    <router-view />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Auth',
  created() {
    this.setShowSideBar(false)
    this.setShowAppBar(false)
  },
  methods: {
    ...mapActions(['setShowSideBar', 'setShowAppBar'])
  }
}
</script>

<style lang="scss" scoped>
.auth {
  position: relative;
}
</style>
