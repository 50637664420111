import store from '@/store'

export default async function checkAuth(to, _from, next) {
  const requiresAuth = to.matched.some(m => m.meta.requiresAuth)
  if (!requiresAuth) {
    return next()
  }
  await store.dispatch('auth/init')
  if (store.getters['auth/isUserLoggedIn']) {
    return next()
  }
  // if user is not authenticated and route requires auth,
  // force clear user store and redirect to login page
  store.dispatch('auth/logout')
  const query = {}
  if (to.fullPath && to.fullPath !== '/') {
    query.next = to.fullPath
  }
  next({ name: 'auth', query })
}
