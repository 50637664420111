import {
  DEFAULT_GETTERS,
  DEFAULT_PAGINATION,
  DEFAULT_STATE,
  gen_crud_actions,
  gen_crud_mutations
} from '@bigml/bmlapp/src/core/store/resources'

import { User } from '@/core/api'
import { DEFAULT_ITEMS_PER_PAGE } from '@/core/config'

const PAGINATION = { ...DEFAULT_PAGINATION(DEFAULT_ITEMS_PER_PAGE) }
const INIT_STATE = { ...DEFAULT_STATE(), me: false }

export default {
  namespaced: true,
  state: {
    ...INIT_STATE,
    pagination: PAGINATION
  },
  getters: {
    ...DEFAULT_GETTERS,
    me({ me }) {
      return me
    }
  },
  actions: {
    ...gen_crud_actions(User),
    async me({ commit }) {
      commit('setLoadingResource', true)
      try {
        const { data } = await User.me()
        commit('setMe', data)
      } finally {
        commit('setLoadingResource', false)
      }
    }
  },
  mutations: {
    ...gen_crud_mutations(INIT_STATE),
    setMe(state, me) {
      state.me = me
    }
  }
}
