import { APP_TITLE } from '@/core/config'
import i18n from '@/plugins/i18n'

export default {
  path: '/project/:id',
  name: 'project-detail',
  props: true,
  meta: {
    requiresAuth: false,
    title: i18n.t('page-titles.intranet', [APP_TITLE])
  },
  component: () => import('@/views/Project/ProjectDetail.vue')
}
