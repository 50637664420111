import { APP_TITLE } from '@/core/config'
import i18n from '@/plugins/i18n'

export default {
  path: '/intranet',
  name: 'intranet',
  meta: {
    requiresAuth: true,
    title: i18n.t('page-titles.intranet', [APP_TITLE])
  },
  component: () => import('@/views/Intranet/Intranet.vue')
}
