<template>
  <v-dialog
    :value="value"
    scrollable
    max-width="600px"
    @input="v => $emit('update:value', v)"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Select rejection reason</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="confirm">Reject</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-divider></v-divider>
      <v-card-text style="height: auto">
        <v-form>
          <v-radio-group v-model="reason" column mandatory>
            <v-radio :label="reasons['accessibility']" value="accessibility"></v-radio>
            <v-radio :label="reasons['project']" value="project"></v-radio>
            <v-radio :label="reasons['duplicated']" value="duplicated"></v-radio>
            <v-radio :label="reasons['other']" value="other"></v-radio>
          </v-radio-group>
          <v-text-field
            v-model="reasonDescription"
            :rules="[rules.required, rules.counter]"
            outlined
            counter
            maxlength="140"
            clearable
            label="Description"
            v-if="reason == 'other'"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'IntranetRejectionModal',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      reason: 'other',
      reasonDescription: '',
      reasons: {
        accessibility: 'Project is not accessibility-related',
        project: 'This is not a project',
        duplicated: 'Project is duplicated',
        other: 'Other reason. You can describe it below'
      },
      rules: {
        required: value => !!value || 'Description required.',
        counter: value => (value && value.length > 0) || 'Description cannot be empty'
      }
    }
  },
  methods: {
    confirm: function () {
      let reason = this.reason
      if (reason == 'other') {
        reason = this.reasonDescription
      } else {
        reason = this.reasons[reason]
      }
      this.$emit('confirm', reason)
      this.$emit('update:value', false)
    }
  }
}
</script>
