export default {
  theme({ theme }) {
    return theme
  },
  showAppBar({ showAppBar }) {
    return showAppBar
  },
  showSideBar({ showSideBar }) {
    return showSideBar
  },
  activeFiltersTool({ activeFiltersTool }) {
    return activeFiltersTool
  },
  activeFiltersIntranet({ activeFiltersIntranet }) {
    return activeFiltersIntranet
  }
}
