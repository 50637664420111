import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import getters from './getters'
import modules from './modules'
import mutations from './mutations'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // Only available theme is light
    //theme: localStorage.getItem('theme') || 'dark'
    theme: 'light',
    showAppBar: true,
    showSideBar: true,
    activeFiltersTool: false,
    activeFiltersIntranet: false
  },
  getters,
  mutations,
  actions,
  modules
})
