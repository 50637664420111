import { isPlainObject } from 'lodash'
import requireContext from 'require-context.macro'

const modules = requireContext('./modules/', false, /.+\.js$/i)

const routes = modules
  .keys()
  .map(k => modules(k)?.default)
  .filter(isPlainObject)

export default [{ path: '/', redirect: { name: 'tool' } }, ...routes]
