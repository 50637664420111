var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bml-vuex-resource-iterator',{attrs:{"auto-refresh":"","vuex-namespace":"project","hide-default-footer":"","cols-props":{ cols: 12, md: 6, xl: 4 },"external-filters":_vm.filters,"auto-refresh-interval":30000},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var loading = ref.loading;
return [(loading)?_c('v-progress-linear',{staticClass:"mb-5",attrs:{"color":"primary","indeterminate":""}}):_vm._e()]}},{key:"loading",fn:function(){return [_c('div',{staticStyle:{"height":"100px","text-align":"center"}},[_c('p',[_vm._v("Loading. Please wait...")])])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('intranet-project-card',_vm._g({attrs:{"project":item}},_vm.$listeners))]}},{key:"footer",fn:function(ref){
var loading = ref.loading;
var pagination = ref.pagination;
var totalPages = ref.totalPages;
return [_c('div',{staticClass:"text-center mt-8 mb-8"},[(loading && totalPages > 0)?_c('v-progress-linear',{staticClass:"mb-5",attrs:{"color":"primary","height":"5","indeterminate":""}}):_vm._e(),(totalPages > 1)?_c('v-pagination',{attrs:{"length":totalPages,"total-visible":7,"circle":""},model:{value:(pagination.page),callback:function ($$v) {_vm.$set(pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }