export default {
  path: '/auth',
  component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/Auth.vue'),
  children: [
    {
      path: '',
      name: 'auth',
      redirect: { name: 'auth.login' }
    },
    {
      path: 'login',
      name: 'auth.login',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/Login.vue')
    },
    {
      path: 'logout',
      name: 'auth.logout',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/Logout.vue')
    }
  ]
}
