import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import SASS_VARIABLES from '@/assets/scss/main.scss'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    options: { customProperties: true },
    themes: {
      light: {
        primary: SASS_VARIABLES['color-theme-light-primary'],
        secondary: SASS_VARIABLES['color-theme-light-secondary'],
        accent: SASS_VARIABLES['color-theme-light-accent'],
        error: SASS_VARIABLES['color-theme-light-error'],
        info: SASS_VARIABLES['color-theme-light-info'],
        success: SASS_VARIABLES['color-theme-light-success'],
        warning: SASS_VARIABLES['color-theme-light-warning']
      },
      dark: {
        primary: SASS_VARIABLES['color-theme-dark-primary'],
        secondary: SASS_VARIABLES['color-theme-dark-secondary'],
        accent: SASS_VARIABLES['color-theme-dark-accent'],
        error: SASS_VARIABLES['color-theme-dark-error'],
        info: SASS_VARIABLES['color-theme-dark-info'],
        success: SASS_VARIABLES['color-theme-dark-success'],
        warning: SASS_VARIABLES['color-theme-dark-warning']
      }
    }
  }
})
