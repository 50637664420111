export default {
  /* Execute all the clear mutations available */
  clear({ commit }) {
    const muts = Object.keys(this._mutations).filter(m => m.endsWith('/clear'))
    muts.forEach(m => commit(m))
  },
  setTheme({ commit }, payload) {
    commit('setTheme', payload)
  },
  setThemeDarkBool({ commit }, payload) {
    commit('setThemeDarkBool', payload)
  },
  setShowAppBar({ commit }, payload) {
    commit('setShowAppBar', payload)
  },
  setShowSideBar({ commit }, payload) {
    commit('setShowSideBar', payload)
  },
  setActiveFiltersTool({ commit }, payload) {
    commit('setActiveFiltersTool', payload)
  },
  setActiveFiltersIntranet({ commit }, payload) {
    commit('setActiveFiltersIntranet', payload)
  }
}
