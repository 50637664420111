import { APP_TITLE } from '@/core/config'
import i18n from '@/plugins/i18n'

export default {
  path: '/new-project',
  name: 'new-project',
  meta: {
    requiresAuth: false,
    title: i18n.t('page-titles.project-new', [APP_TITLE])
  },
  component: () => import('@/views/New/NewProject.vue')
}
