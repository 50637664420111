/**
 * Global alert object to notify important events
 *
 */

export default {
  namespaced: true,
  state: {
    alertType: null,
    message: null
  },
  getters: {
    alertType({ alertType }) {
      return alertType
    },
    hasAlert({ alertType, message }) {
      return !!alertType && !!message
    },
    message({ message }) {
      return message
    }
  },
  actions: {
    clear({ commit }) {
      commit('clear')
    },
    error({ commit }, message) {
      commit('set', { alertType: 'error', message })
    },
    info({ commit }, message) {
      commit('set', { alertType: 'info', message })
    },
    success({ commit }, message) {
      commit('set', { alertType: 'success', message })
    }
  },
  mutations: {
    clear(state) {
      state.alertType = null
      state.alertType = null
    },
    set(state, { alertType, message }) {
      state.message = null
      // setTimeout needed to animate when message changes,
      // as per MD spec snackbars should't stack, but instead
      // close and reopen.
      // https://github.com/vuetifyjs/vuetify/issues/2384
      setTimeout(() => {
        state.message = message
        state.alertType = alertType
      }, 250)
    }
  }
}
