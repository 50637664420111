import {
  DEFAULT_GETTERS,
  DEFAULT_PAGINATION,
  DEFAULT_STATE,
  gen_crud_actions,
  gen_crud_mutations
} from '@bigml/bmlapp/src/core/store/resources'

import { Map } from '@/core/api'
import { DEFAULT_ITEMS_PER_PAGE } from '@/core/config'

const PAGINATION = { ...DEFAULT_PAGINATION(DEFAULT_ITEMS_PER_PAGE), sortBy: [] }
const INIT_STATE = { ...DEFAULT_STATE() }

export default {
  namespaced: true,
  state: {
    ...INIT_STATE,
    pagination: PAGINATION
  },
  getters: { ...DEFAULT_GETTERS },
  actions: {
    ...gen_crud_actions(Map),
    async list({ commit }, payload = {}) {
      commit('setLoadingList', true)
      try {
        const { data } = await Map.list(payload)
        if (typeof data === 'undefined') {
          // if this happens we got an error from the API. Do not reset
          return
        }
        // At this point we are mostly sure that the request worked,
        // so we can clear the list of resource IDs
        commit('clearSortedResourcesIDs')
        if (data && data.length) {
          commit('clearResources')
          data.forEach(item => commit('set', { bmlAddToList: true, ...item }))
        }
        commit('setTotalResources', data.length)
      } finally {
        commit('setLoadingList', false)
      }
    }
  },
  mutations: { ...gen_crud_mutations(INIT_STATE) }
}
