<template>
  <v-container class="ml-1 mr-1 pa-3" fluid>
    <v-row>
      <v-col cols="12">
        <intranet-filter-bar
          v-bind.sync="filter"
          @search="search"
          @reset="resetFilters"
        />
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="12">
        <intranet-project-list
          :filters="queryFilter"
          @reject="reject"
          @publish="publish"
        />
      </v-col>
    </v-row>
    <v-row v-if="rejectDialog">
      <intranet-rejection-modal :value.sync="rejectDialog" @confirm="rejectConfirm" />
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import IntranetFilterBar from '@/views/Intranet/IntranetFilterBar.vue'
import IntranetProjectList from '@/views/Intranet/IntranetProjectList.vue'
import IntranetRejectionModal from '@/views/Intranet/IntranetRejectionModal.vue'

const INIT_FILTER = {
  telework: true,
  elearning: true,
  telecare: true,
  other: true,
  unknown: true,
  labels: '',
  source: null,
  status: 'PENDING',
  country: 'All countries'
}

export default {
  name: 'Intranet',
  components: { IntranetProjectList, IntranetFilterBar, IntranetRejectionModal },
  data() {
    return {
      filter: { ...INIT_FILTER },
      rejectDialog: false,
      rejectInfo: {},
      queryFilter: this.getQueryFilter(INIT_FILTER)
    }
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters({ activeFiltersIntranet: 'activeFiltersIntranet' }),
    labels: function () {
      let value = ''
      if (this.filter.telework) {
        value += 'TELEWORK,'
      }
      if (this.filter.telecare) {
        value += 'TELECARE,'
      }
      if (this.filter.elearning) {
        value += 'ELEARNING,'
      }
      if (this.filter.other) {
        value += 'OTHER,'
      }
      if (this.filter.unknown) {
        value += 'UNKNOWN,'
      }
      return value.slice(0, -1)
    }
  },
  beforeDestroy() {
    this.setActiveFiltersIntranet({ ...this.filter })
  },

  created() {
    this.setShowSideBar(true)
    this.setShowAppBar(true)
    if (this.activeFiltersIntranet) {
      this.filter = { ...this.activeFiltersIntranet }
    }
    this.queryFilter = this.getQueryFilter(this.filter)
  },
  methods: {
    ...mapActions(['setShowSideBar', 'setShowAppBar', 'setActiveFiltersIntranet']),
    ...mapActions('project', { projectUpdate: 'update' }),
    ...mapActions('review', { reviewUpdate: 'update' }),
    getQueryFilter: function (filter) {
      let country = filter.country
      if (country == 'All countries') {
        country = null
      }
      return {
        countries: country,
        review_status: filter.status,
        labels: this.labels,
        source: filter.source,
        // Show only projects with an associated review
        review_not_null: true
      }
    },
    search: function () {
      this.queryFilter = this.getQueryFilter(this.filter)
    },
    resetFilters: function () {
      this.filter = { ...INIT_FILTER }
    },
    // Same function in Search.vue
    reject: function (project, labels) {
      this.rejectDialog = true
      this.rejectInfo = { project, labels }
    },
    // Same function in Search.vue
    rejectConfirm: async function (reason) {
      let labels = this.rejectInfo.labels
      let project = this.rejectInfo.project
      let reason_user = this.me.username + '||' + reason
      let review = { review_status: 'REJECTED', comment: reason_user }
      await this.projectUpdate({ id: project.id, labels })
      await this.reviewUpdate({ id: project.review.id, ...review })
      // Force refresh after update
      await this.search()
    },
    // Same function in Search.vue
    publish: async function (project, labels) {
      let review = { review_status: 'PUBLISHED', comment: '' }
      await this.projectUpdate({ id: project.id, labels })
      await this.reviewUpdate({ id: project.review.id, ...review })
      // Force refresh after update
      await this.search()
    }
  }
}
</script>
