<template>
  <div class="logout" />
</template>
<script>
export default {
  name: 'Logout',
  created() {
    this.$store.dispatch('auth/logout')
    this.$store.dispatch('clear')
    this.$router.push({ name: 'auth' })
  }
}
</script>
